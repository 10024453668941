var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "class-box-container" }, [
    _vm.courseClassify === 0
      ? _c("div", { staticClass: "class-list" }, [
          _c("div", { staticClass: "class-item" }, [
            _c("div", { staticClass: "class-top" }, [
              _c("div", { staticClass: "class-time font-menu-large" }, [
                _c("span", [_vm._v(_vm._s(_vm.classListInfo.startTime))]),
              ]),
              _c(
                "div",
                { staticClass: "class-classify-wrapper font-menu-small" },
                [
                  _c("div", { staticClass: "class-classify" }, [
                    _vm._v(_vm._s(_vm.classListInfo.classType)),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "class-content",
                on: {
                  mouseleave: function ($event) {
                    _vm.isOpenCancellBtn = false
                    _vm.isCancellDotHover = false
                  },
                },
              },
              [
                _c("div", { staticClass: "class-content-logo" }, [
                  _c("img", { attrs: { src: _vm.chapterCoverImg, alt: "" } }),
                ]),
                _c(
                  "div",
                  { staticClass: "class-content-detail font-menu-small" },
                  [
                    _c("div", { staticClass: "course" }, [
                      _c("span", [
                        _vm._v(
                          "LEVEL " +
                            _vm._s(_vm.classListInfo.levelIndex) +
                            " • "
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          " CHAPTER " +
                            _vm._s(_vm.classListInfo.chapterIndex) +
                            " • "
                        ),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.classListInfo.chapterTitleEn)),
                      ]),
                    ]),
                    _c("div", { staticClass: "class-content-title" }, [
                      _c("div", { staticClass: "lesson font-menu-large" }, [
                        _vm._v(
                          " Lesson " +
                            _vm._s(_vm.classListInfo.lessonIndex) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "title english" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.classListInfo.lessonTitleEnglish) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.classListInfo.lessonTitleHanzi)),
                      ]),
                      _c("div", { staticClass: "spelling font-menu-small" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.classListInfo.lessonTitlePinyin) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "class-teacher" }, [
                  _c("div", { staticClass: "teacher-info" }, [
                    _c(
                      "div",
                      { staticClass: "teacher-name font-menu-medium" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.classListInfo.teacherName) + " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "teacher-avatar" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.classListInfo.teacherAvatar,
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                  _vm.courseClassify === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "learning-goals font-menu-small",
                          on: {
                            click: function ($event) {
                              _vm.goalsDialogVisible = true
                            },
                          },
                        },
                        [_vm._v(" Learning Goals ")]
                      )
                    : _vm._e(),
                ]),
                _vm.canCancelClass
                  ? _c("div", { staticClass: "cancell-class" }, [
                      _vm.isCancellDotHover
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/19-Dashboard/menu-dots-blue.svg"),
                            },
                          })
                        : _vm._e(),
                      !_vm.isCancellDotHover
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/19-Dashboard/menu-dots-grey.svg"),
                            },
                            on: { mouseenter: _vm.handleHoverDot },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.isOpenCancellBtn
                  ? _c(
                      "div",
                      {
                        staticClass: "cancel-button font-menu-small",
                        on: {
                          click: function ($event) {
                            _vm.cancelClassDialogVisible = true
                          },
                          mouseenter: function ($event) {
                            _vm.isOpenCancellBtn = true
                          },
                          mouseleave: function ($event) {
                            _vm.isOpenCancellBtn = false
                            _vm.isCancellDotHover = false
                          },
                        },
                      },
                      [_c("span", [_vm._v("Cancel this class")])]
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "learning-class font-menu-small" }, [
              this.classListInfo.lessonIndex !== 8 &&
              this.classListInfo.lessonIndex !== 16
                ? _c(
                    "div",
                    {
                      staticClass: "preview",
                      on: { click: _vm.jumpToPreview },
                    },
                    [_vm._v(" Preview ")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "enter-class",
                  class: { enterClass: _vm.canEnterClass },
                  on: {
                    click: function ($event) {
                      return _vm.enterInClass(_vm.classListInfo.lessonIndex)
                    },
                  },
                },
                [_vm._v(" Enter Classroom ")]
              ),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "class-list" }, [
          _c("div", { staticClass: "class-item" }, [
            _c("div", { staticClass: "class-top" }, [
              _c("div", { staticClass: "class-time font-menu-large" }, [
                _c("span", [_vm._v(_vm._s(_vm.classListInfo.startTime))]),
              ]),
              _c(
                "div",
                { staticClass: "class-classify-wrapper font-menu-small" },
                [
                  _c("div", { staticClass: "class-classify" }, [
                    _vm._v(_vm._s(_vm.classListInfo.classType)),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "class-content" }, [
              _vm.courseClassify === 1
                ? _c("div", { staticClass: "class-content-logo" }, [
                    _c("img", { attrs: { src: _vm.chapterCoverImg, alt: "" } }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "completed-class-detail" }, [
                _c(
                  "div",
                  { staticClass: "completed-class-intro font-menu-small" },
                  [
                    _c("span", { staticClass: "level" }, [
                      _vm._v(
                        "LEVEL " + _vm._s(_vm.classListInfo.levelIndex) + " • "
                      ),
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.classListInfo.time)),
                    ]),
                    _c("span", [
                      _vm._v(
                        "CHAPTER " +
                          _vm._s(_vm.classListInfo.chapterIndex) +
                          " • "
                      ),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.classListInfo.chapterTitleEn) + " "),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "lesson-box font-menu-small" }, [
                  _c("div", { staticClass: "lesson font-menu-large" }, [
                    _vm._v(
                      " Lesson " + _vm._s(_vm.classListInfo.lessonIndex) + " "
                    ),
                  ]),
                  _vm.courseClassify === 1
                    ? _c("div", { staticClass: "title english" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.classListInfo.lessonTitleEnglish) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.courseClassify === 1
                    ? _c("div", { staticClass: "title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.classListInfo.lessonTitleHanzi) + " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.courseClassify === 1
                    ? _c("div", { staticClass: "spelling font-menu-small" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.classListInfo.lessonTitlePinyin) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "class-teacher" }, [
                _c("div", { staticClass: "teacher-info" }, [
                  _c("div", { staticClass: "teacher-name font-menu-medium" }, [
                    _vm._v(" " + _vm._s(_vm.classListInfo.teacherName) + " "),
                  ]),
                  _c("div", { staticClass: "teacher-avatar" }, [
                    _c("img", {
                      attrs: { src: _vm.classListInfo.teacherAvatar, alt: "" },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "completed-course" }, [
              _vm.courseClassify === 1
                ? _c("div", { staticClass: "review-course font-menu-small" }, [
                    _c(
                      "div",
                      {
                        staticClass: "comment",
                        on: { click: _vm.openStudentComment },
                      },
                      [_vm._v("Comment")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "review",
                        on: { click: _vm.jumpToPreview },
                      },
                      [_vm._v("Review")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
    _c(
      "div",
      { staticClass: "goals-dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Learning Goals",
              visible: _vm.goalsDialogVisible,
              width: "35%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.goalsDialogVisible = $event
              },
            },
          },
          [
            _c("PageLayout", {
              scopedSlots: _vm._u([
                {
                  key: "page-name",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "nav-title font-menu-large" },
                        _vm._l(_vm.goalsClassify, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "nav-title-item",
                              class: { active: item.id === _vm.goalsLanguage },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage(item.id)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "page-content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "course-info" }, [
                        _c(
                          "div",
                          {
                            staticClass: "course-info-content font-menu-large",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "LEVEL " +
                                  _vm._s(_vm.classListInfo.levelIndex) +
                                  " • "
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                "CHAPTER " +
                                  _vm._s(_vm.classListInfo.chapterIndex) +
                                  " • "
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.classListInfo.chapterTitleEn) + " "
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "class-content-title" }, [
                          _c("div", { staticClass: "lesson font-menu-large" }, [
                            _vm._v(
                              " Lesson " +
                                _vm._s(_vm.classListInfo.lessonIndex) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "title english" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.classListInfo.lessonTitleEnglish) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.classListInfo.lessonTitleHanzi) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "language" }, [
                          _c("div", { staticClass: "goals-area" }, [
                            _c("div", { staticClass: "i-can-speak" }, [
                              _c(
                                "div",
                                { staticClass: "title font-menu-large" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.learningGoalsInfo.speakTitle) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "text-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.learningGoalsInfo.speak) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "i-can-see" }, [
                              _c(
                                "div",
                                { staticClass: "title font-menu-large" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.learningGoalsInfo.useTitle) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "text-content" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.learningGoalsInfo.use) + " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "feedback-dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Feedback",
              visible: _vm.feedbackDialogVisible,
              width: "60vw",
              height: "85vh",
              center: "",
              top: "5vh",
              "lock-scroll": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.feedbackDialogVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "class-top" }, [
              _c("div", { staticClass: "class-time font-menu-large" }, [
                _c("span", [_vm._v(_vm._s(_vm.classListInfo.startTime))]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.courseClassify === 1,
                      expression: "courseClassify === 1",
                    },
                  ],
                  staticClass: "status",
                },
                [_vm._v(" " + _vm._s(_vm.classListInfo.status) + " ")]
              ),
            ]),
            _c("div", { staticClass: "course-info" }, [
              _c(
                "div",
                { staticClass: "course-info-content font-menu-small" },
                [
                  _c("span", [
                    _vm._v(
                      "LEVEL " + _vm._s(_vm.classListInfo.levelIndex) + " • "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "CHAPTER " +
                        _vm._s(_vm.classListInfo.chapterIndex) +
                        " • "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.classListInfo.chapterTitleEn) + " "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "class-content-title" }, [
                _c("div", { staticClass: "lesson font-menu-large" }, [
                  _vm._v(
                    " Lesson " + _vm._s(_vm.classListInfo.lessonIndex) + " "
                  ),
                ]),
                _c("div", { staticClass: "title english" }, [
                  _vm._v(
                    " " + _vm._s(_vm.classListInfo.lessonTitleEnglish) + " "
                  ),
                ]),
                _c("div", { staticClass: "title font-menu-large" }, [
                  _vm._v(
                    " " + _vm._s(_vm.classListInfo.lessonTitleHanzi) + " "
                  ),
                ]),
                _c("div", { staticClass: "spelling font-menu-small" }, [
                  _vm._v(
                    " " + _vm._s(_vm.classListInfo.lessonTitlePinyin) + " "
                  ),
                ]),
              ]),
            ]),
            _c("PageLayout", {
              scopedSlots: _vm._u([
                {
                  key: "page-name",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "nav-title font-menu-large" },
                        _vm._l(_vm.feedbackClassifyList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "nav-title-item active",
                              on: {
                                click: function ($event) {
                                  return _vm.changeFeedback(item.id)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "title-test" }, [
                                _vm._v(" " + _vm._s(item.title) + " "),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "page-content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "content" }, [
                        !_vm.isStudentFinishedFeedback
                          ? _c(
                              "div",
                              { staticClass: "feedback-content student" },
                              [
                                _c("div", { staticClass: "star-rating" }, [
                                  _c("div", { staticClass: "feedback-left" }, [
                                    _c(
                                      "div",
                                      { staticClass: "yellow-circle" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/19-Dashboard/yellow-dot.svg"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        " Please give your teacher a 1-5 star rating "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "star-img" },
                                    _vm._l(_vm.starArr, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index + "star",
                                          staticClass: "img-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickStar(index)
                                            },
                                          },
                                        },
                                        [
                                          item.starLevel === 0
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/img/07-Stars/star-empty.svg"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                          item.starLevel === 1
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/img/07-Stars/star-half.svg"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                          item.starLevel === 2
                                            ? _c("img", {
                                                attrs: {
                                                  src: require("@/assets/img/07-Stars/star-full.svg"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                                _c("div", { staticClass: "student-textarea" }, [
                                  _c("div", { staticClass: "feedback-left" }, [
                                    _c(
                                      "div",
                                      { staticClass: "yellow-circle" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/19-Dashboard/yellow-dot.svg"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        " What would you like to tell your teacher? "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "student-textarea-content" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 4, maxRows: 4 },
                                          placeholder: "Type...",
                                        },
                                        model: {
                                          value: _vm.studentInput,
                                          callback: function ($$v) {
                                            _vm.studentInput = $$v
                                          },
                                          expression: "studentInput",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "lesson-diffcult" }, [
                                  _c("div", { staticClass: "feedback-left" }, [
                                    _c(
                                      "div",
                                      { staticClass: "yellow-circle" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/img/19-Dashboard/yellow-dot.svg"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        "Rate the difficulty of this lesson:"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "lesson-diffcult-content" },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.difficultyOfLesson,
                                            callback: function ($$v) {
                                              _vm.difficultyOfLesson = $$v
                                            },
                                            expression: "difficultyOfLesson",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "item",
                                              attrs: { label: "Easy" },
                                            },
                                            [_vm._v("Easy")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "item",
                                              attrs: { label: "Moderate" },
                                            },
                                            [_vm._v("Moderate")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "item",
                                              attrs: { label: "Hard" },
                                            },
                                            [_vm._v("Hard")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "item",
                                              attrs: { label: "Too hard" },
                                            },
                                            [_vm._v("Too hard")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "button-area" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cancle-btn btn",
                                      on: {
                                        click: function ($event) {
                                          _vm.feedbackDialogVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "save-btn btn",
                                      on: { click: _vm.submitStedentFeedback },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                ]),
                              ]
                            )
                          : _c("div", { staticClass: "finished-feedback" }, [
                              _c("div", { staticClass: "student-info" }, [
                                _c("div", { staticClass: "student-avatar" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.finishedStudentFeedbackInfo
                                        .studentAvatar,
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "student-name font-menu-medium",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.finishedStudentFeedbackInfo
                                            .studentName
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "star-level" },
                                [
                                  _c("StarScore", {
                                    attrs: {
                                      score: String(
                                        _vm.finishedStudentFeedbackInfo
                                          .teacherScore
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "student-commemt" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.finishedStudentFeedbackInfo
                                        .feedbackContent
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "lesson-diffcult" }, [
                                _c("div", { staticClass: "feedback-left" }, [
                                  _c("div", { staticClass: "yellow-circle" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/19-Dashboard/yellow-dot.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "Rate the difficulty of this lesson:"
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "lesson-diffcult-content" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.finishedStudentFeedbackInfo
                                              .learningLevel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.finishedStudentFeedbackInfo,
                                              "learningLevel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "finishedStudentFeedbackInfo.learningLevel",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "item",
                                            attrs: { label: "Easy" },
                                          },
                                          [_vm._v("Easy")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "item",
                                            attrs: { label: "Moderate" },
                                          },
                                          [_vm._v("Moderate")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "item",
                                            attrs: { label: "Hard" },
                                          },
                                          [_vm._v("Hard")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "item",
                                            attrs: { label: "Too hard" },
                                          },
                                          [_vm._v("Too hard")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "cancell-class-dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Class Cancellation",
              visible: _vm.cancelClassDialogVisible,
              center: "",
              "lock-scroll": false,
              top: "30vh",
            },
            on: {
              "update:visible": function ($event) {
                _vm.cancelClassDialogVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "cancell-content" }, [
              _c("div", { staticClass: "question" }, [
                _vm._v(" Are you sure you’d like to cancel this class? "),
              ]),
              _c("div", { staticClass: "cancel-btn-area" }, [
                _c(
                  "div",
                  {
                    staticClass: "not-cancel font-menu-small",
                    on: {
                      click: function ($event) {
                        _vm.cancelClassDialogVisible = false
                      },
                    },
                  },
                  [_vm._v(" No, keep it ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "cancel-btn font-menu-small",
                    on: { click: _vm.submitCancelClass },
                  },
                  [_vm._v(" Yes, cancel the class ")]
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }