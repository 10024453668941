<template>
  <div class="class-box-container">
    <div class="class-list" v-if="courseClassify === 0">
      <div class="class-item">
        <!-- 课程头部时间类型 -->
        <div class="class-top">
          <div class="class-time font-menu-large">
            <span>{{ classListInfo.startTime }}</span>
          </div>
          <div class="class-classify-wrapper font-menu-small">
            <div class="class-classify">{{ classListInfo.classType }}</div>
          </div>
        </div>
        <!-- 课程主体内容 -->
        <div
          class="class-content"
          @mouseleave="
            isOpenCancellBtn = false;
            isCancellDotHover = false;
          "
        >
          <div class="class-content-logo">
            <img :src="chapterCoverImg" alt="" />
          </div>

          <div class="class-content-detail font-menu-small">
            <div class="course">
              <span>LEVEL {{ classListInfo.levelIndex }} • </span>
              <span> CHAPTER {{ classListInfo.chapterIndex }} • </span>
              <span>{{ classListInfo.chapterTitleEn }}</span>
            </div>
            <div class="class-content-title">
              <div class="lesson font-menu-large">
                Lesson {{ classListInfo.lessonIndex }}
              </div>
              <div class="title english">
                {{ classListInfo.lessonTitleEnglish }}
              </div>
              <div class="title">{{ classListInfo.lessonTitleHanzi }}</div>
              <div class="spelling font-menu-small">
                {{ classListInfo.lessonTitlePinyin }}
              </div>
            </div>
          </div>

          <div class="class-teacher">
            <div class="teacher-info">
              <div class="teacher-name font-menu-medium">
                {{ classListInfo.teacherName }}
              </div>
              <div class="teacher-avatar">
                <img :src="classListInfo.teacherAvatar" alt="" />
              </div>
            </div>
            <div
              class="learning-goals font-menu-small"
              v-if="courseClassify === 0"
              @click="goalsDialogVisible = true"
            >
              Learning Goals
            </div>
            <!-- <router-link></router-link> -->
          </div>

          <div class="cancell-class" v-if="canCancelClass">
            <img
              src="@/assets/img/19-Dashboard/menu-dots-blue.svg"
              v-if="isCancellDotHover"
            />
            <img
              src="@/assets/img/19-Dashboard/menu-dots-grey.svg"
              @mouseenter="handleHoverDot"
              v-if="!isCancellDotHover"
            />
          </div>
          <div
            class="cancel-button font-menu-small"
            @click="cancelClassDialogVisible = true"
            v-if="isOpenCancellBtn"
            @mouseenter="isOpenCancellBtn = true"
            @mouseleave="
              isOpenCancellBtn = false;
              isCancellDotHover = false;
            "
          >
            <span>Cancel this class</span>
          </div>
        </div>

        <!-- 进入课程 -->
        <div class="learning-class font-menu-small">
          <div
            class="preview"
            v-if="
              this.classListInfo.lessonIndex !== 8 &&
              this.classListInfo.lessonIndex !== 16
            "
            @click="jumpToPreview"
          >
            Preview
          </div>
          <!-- <div class="enter-class">Enter ClassRoom</div> -->
          <div
            class="enter-class"
            :class="{ enterClass: canEnterClass }"
            @click="enterInClass(classListInfo.lessonIndex)"
          >
            Enter Classroom
          </div>
        </div>
      </div>
    </div>
    <div class="class-list" v-else>
      <div class="class-item">
        <div class="class-top">
          <div class="class-time font-menu-large">
            <span>{{ classListInfo.startTime }}</span>
          </div>
          <div class="class-classify-wrapper font-menu-small">
            <div class="class-classify">{{ classListInfo.classType }}</div>
          </div>
        </div>
        <div class="class-content">
          <div class="class-content-logo" v-if="courseClassify === 1">
            <img :src="chapterCoverImg" alt="" />
          </div>

          <div class="completed-class-detail">
            <div class="completed-class-intro font-menu-small">
              <span class="level">LEVEL {{ classListInfo.levelIndex }} • </span>
              <span class="time">{{ classListInfo.time }}</span>
              <span>CHAPTER {{ classListInfo.chapterIndex }} • </span>
              <span>{{ classListInfo.chapterTitleEn }} </span>
            </div>

            <div class="lesson-box font-menu-small">
              <div class="lesson font-menu-large">
                Lesson {{ classListInfo.lessonIndex }}
              </div>
              <div class="title english" v-if="courseClassify === 1">
                {{ classListInfo.lessonTitleEnglish }}
              </div>
              <div class="title" v-if="courseClassify === 1">
                {{ classListInfo.lessonTitleHanzi }}
              </div>
              <div class="spelling font-menu-small" v-if="courseClassify === 1">
                {{ classListInfo.lessonTitlePinyin }}
              </div>
            </div>
          </div>
          <div class="class-teacher">
            <div class="teacher-info">
              <div class="teacher-name font-menu-medium">
                {{ classListInfo.teacherName }}
              </div>
              <div class="teacher-avatar">
                <img :src="classListInfo.teacherAvatar" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="completed-course">
          <div
            class="review-course font-menu-small"
            v-if="courseClassify === 1"
          >
            <div class="comment" @click="openStudentComment">Comment</div>
            <div class="review" @click="jumpToPreview">Review</div>
          </div>
        </div>
      </div>
    </div>
    <!-- learning Goals -->
    <div class="goals-dialog">
      <el-dialog
        title="Learning Goals"
        :visible.sync="goalsDialogVisible"
        width="35%"
        center
      >
        <PageLayout>
          <template #page-name>
            <div class="nav-title font-menu-large">
              <div
                class="nav-title-item"
                v-for="item in goalsClassify"
                :key="item.id"
                :class="{ active: item.id === goalsLanguage }"
                @click="changeLanguage(item.id)"
              >
                {{ item.title }}
              </div>
            </div>
          </template>
          <template #page-content>
            <div class="course-info">
              <div class="course-info-content font-menu-large">
                <span>LEVEL {{ classListInfo.levelIndex }} • </span>
                <span>CHAPTER {{ classListInfo.chapterIndex }} • </span>
                <span>{{ classListInfo.chapterTitleEn }} </span>
              </div>
              <div class="class-content-title">
                <div class="lesson font-menu-large">
                  Lesson {{ classListInfo.lessonIndex }}
                </div>
                <div class="title english">
                  {{ classListInfo.lessonTitleEnglish }}
                </div>
                <div class="title">
                  {{ classListInfo.lessonTitleHanzi }}
                </div>
              </div>
            </div>
            <div class="content">
              <div class="language">
                <div class="goals-area">
                  <div class="i-can-speak">
                    <div class="title font-menu-large">
                      {{ learningGoalsInfo.speakTitle }}
                    </div>
                    <div class="text-content">
                      {{ learningGoalsInfo.speak }}
                    </div>
                  </div>
                  <div class="i-can-see">
                    <div class="title font-menu-large">
                      {{ learningGoalsInfo.useTitle }}
                    </div>
                    <div class="text-content">
                      {{ learningGoalsInfo.use }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </PageLayout>
      </el-dialog>
    </div>
    <!-- feedback -->
    <div class="feedback-dialog">
      <el-dialog
        title="Feedback"
        :visible.sync="feedbackDialogVisible"
        width="60vw"
        height="85vh"
        center
        top="5vh"
        :lock-scroll="false"
      >
        <div class="class-top">
          <div class="class-time font-menu-large">
            <span>{{ classListInfo.startTime }}</span>
          </div>
          <div class="status" v-show="courseClassify === 1">
            {{ classListInfo.status }}
          </div>
        </div>

        <div class="course-info">
          <div class="course-info-content font-menu-small">
            <span>LEVEL {{ classListInfo.levelIndex }} • </span>
            <span>CHAPTER {{ classListInfo.chapterIndex }} • </span>
            <span>{{ classListInfo.chapterTitleEn }} </span>
          </div>
          <div class="class-content-title">
            <div class="lesson font-menu-large">
              Lesson {{ classListInfo.lessonIndex }}
            </div>
            <div class="title english">
              {{ classListInfo.lessonTitleEnglish }}
            </div>
            <div class="title font-menu-large">
              {{ classListInfo.lessonTitleHanzi }}
            </div>
            <div class="spelling font-menu-small">
              {{ classListInfo.lessonTitlePinyin }}
            </div>
          </div>
        </div>

        <PageLayout>
          <template #page-name>
            <div class="nav-title font-menu-large">
              <div
                class="nav-title-item active"
                v-for="item in feedbackClassifyList"
                :key="item.id"
                @click="changeFeedback(item.id)"
              >
                <div class="title-test">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </template>
          <template #page-content>
            <div class="content">
              <!-- 未完成的feedback页面 -->
              <div
                class="feedback-content student"
                v-if="!isStudentFinishedFeedback"
              >
                <div class="star-rating">
                  <div class="feedback-left">
                    <div class="yellow-circle">
                      <img
                        src="@/assets/img/19-Dashboard/yellow-dot.svg"
                        alt=""
                      />
                    </div>
                    <div class="title">
                      Please give your teacher a 1-5 star rating
                    </div>
                  </div>
                  <div class="star-img">
                    <div
                      class="img-item"
                      v-for="(item, index) in starArr"
                      :key="index + 'star'"
                      @click="clickStar(index)"
                    >
                      <img
                        src="@/assets/img/07-Stars/star-empty.svg"
                        alt=""
                        v-if="item.starLevel === 0"
                      />
                      <img
                        src="@/assets/img/07-Stars/star-half.svg"
                        alt=""
                        v-if="item.starLevel === 1"
                      />
                      <img
                        src="@/assets/img/07-Stars/star-full.svg"
                        alt=""
                        v-if="item.starLevel === 2"
                      />
                    </div>
                  </div>
                </div>
                <div class="student-textarea">
                  <div class="feedback-left">
                    <div class="yellow-circle">
                      <img
                        src="@/assets/img/19-Dashboard/yellow-dot.svg"
                        alt=""
                      />
                    </div>
                    <div class="title">
                      What would you like to tell your teacher?
                    </div>
                  </div>
                  <div class="student-textarea-content">
                    <el-input
                      type="textarea"
                      v-model="studentInput"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                      placeholder="Type..."
                    ></el-input>
                  </div>
                </div>
                <div class="lesson-diffcult">
                  <div class="feedback-left">
                    <div class="yellow-circle">
                      <img
                        src="@/assets/img/19-Dashboard/yellow-dot.svg"
                        alt=""
                      />
                    </div>
                    <div class="title">Rate the difficulty of this lesson:</div>
                  </div>
                  <div class="lesson-diffcult-content">
                    <el-radio-group v-model="difficultyOfLesson">
                      <el-radio label="Easy" class="item">Easy</el-radio>
                      <el-radio label="Moderate" class="item"
                        >Moderate</el-radio
                      >
                      <el-radio label="Hard" class="item">Hard</el-radio>
                      <el-radio label="Too hard" class="item"
                        >Too hard</el-radio
                      >
                    </el-radio-group>
                  </div>
                </div>
                <div class="button-area">
                  <div
                    class="cancle-btn btn"
                    @click="feedbackDialogVisible = false"
                  >
                    Cancel
                  </div>
                  <div class="save-btn btn" @click="submitStedentFeedback">
                    Save
                  </div>
                </div>
              </div>
              <!-- 已完成的feedback页面 展示 -->
              <div class="finished-feedback" v-else>
                <div class="student-info">
                  <div class="student-avatar">
                    <img
                      :src="finishedStudentFeedbackInfo.studentAvatar"
                      alt=""
                    />
                  </div>
                  <div class="student-name font-menu-medium">
                    {{ finishedStudentFeedbackInfo.studentName }}
                  </div>
                </div>
                <div class="star-level">
                  <StarScore
                    :score="String(finishedStudentFeedbackInfo.teacherScore)"
                  />
                </div>
                <div class="student-commemt">
                  {{ finishedStudentFeedbackInfo.feedbackContent }}
                </div>
                <div class="lesson-diffcult">
                  <div class="feedback-left">
                    <div class="yellow-circle">
                      <img
                        src="@/assets/img/19-Dashboard/yellow-dot.svg"
                        alt=""
                      />
                    </div>
                    <div class="title">Rate the difficulty of this lesson:</div>
                  </div>
                  <div class="lesson-diffcult-content">
                    <el-radio-group
                      v-model="finishedStudentFeedbackInfo.learningLevel"
                      disabled
                    >
                      <el-radio label="Easy" class="item">Easy</el-radio>
                      <el-radio label="Moderate" class="item"
                        >Moderate</el-radio
                      >
                      <el-radio label="Hard" class="item">Hard</el-radio>
                      <el-radio label="Too hard" class="item"
                        >Too hard</el-radio
                      >
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </PageLayout>
      </el-dialog>
    </div>
    <!-- cancell class -->
    <div class="cancell-class-dialog">
      <el-dialog
        title="Class Cancellation"
        :visible.sync="cancelClassDialogVisible"
        center
        :lock-scroll="false"
        top="30vh"
      >
        <div class="cancell-content">
          <div class="question">
            Are you sure you’d like to cancel this class?
          </div>
          <div class="cancel-btn-area">
            <div
              class="not-cancel font-menu-small"
              @click="cancelClassDialogVisible = false"
            >
              No, keep it
            </div>
            <div class="cancel-btn font-menu-small" @click="submitCancelClass">
              Yes, cancel the class
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// api
import {
  submitStudentComment,
  cancelClass,
  getStudentComment,
} from "@/assets/js/axios/kidsApi";
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import StarScore from "@/components/Page/StarScore";

export default {
  name: "ClassBox",
  props: {
    courseClassify: {
      string: Number,
      require: true,
    },
    classListInfo: {
      string: Array,
      // require: true,
      default: () => {},
    },
  },

  components: {
    PageLayout,
    StarScore,
  },
  data() {
    return {
      cancelClassDialogVisible: false,
      isOpenCancellBtn: false,
      isCancellDotHover: false,
      learningGoalsInfo: {},
      goalsDialogVisible: false,
      feedbackDialogVisible: false,
      goalsLanguage: 0,
      calendarEventId: null, // 房间号
      feedbackClassify: 0,
      goalsClassify: [
        {
          id: 0,
          title: "EN",
        },
        {
          id: 1,
          title: "中文",
        },
      ],
      feedbackClassifyList: [
        // {
        //   id: 0,
        //   title: "Teacher's Feedback",
        // },
        {
          id: 1,
          title: "Your Feedback",
        },
      ],
      starLevel: 0, //星星等级 0 空 1 半 2 满
      studentInput: "",
      difficultyOfLesson: null,
      starFlag: 0,
      starArr: [
        { starLevel: 0 },
        { starLevel: 0 },
        { starLevel: 0 },
        { starLevel: 0 },
        { starLevel: 0 },
      ],
      // teacherFeedbackOfLessonInfo: [],
      // isChapterComment: false,
      // teacherFeedbackCommentOfChapter: [],
      // teacherFeedbackOfChapter: {},
      // isTeacherFinishFeedback: true,

      isStudentFinishedFeedback: false,
      finishedStudentFeedbackInfo: {},

      canEnterClass: false,
      canCancelClass: true,
    };
  },
  created() {
    this.handleEnterClass();
    this.handleCanCancelClass();
    this.calendarEventId = this.classListInfo.calendarEventId;
    this.changeLanguage(this.goalsLanguage);
  },
  updated() {
    this.changeLanguage(this.goalsLanguage);
  },
  computed: {
    chapterCoverImg() {
      return require(`@/assets/img/19-Dashboard/Home/level-${this.classListInfo.levelIndex}-chapter-${this.classListInfo.chapterIndex}.svg`);
    },
  },

  methods: {
    /**
     * @description:  判断课程是否可以取消  18小时之内不可以
     * @param {*}
     * @return {*}
     */
    handleCanCancelClass() {
      const nowTimestamp = new Date().getTime();
      const classEnterTime = new Date(this.classListInfo.classTime).getTime();
      // console.log(nowTimestamp, classEnterTime);
      this.canCancelClass =
        classEnterTime - nowTimestamp >= 18 * 60 * 60 * 1000 ? true : false;
      // console.log(this.canEnterClass);
    },
    /**
     * @description:  判断课程是否到时间 1 hour之内可以进入
     * @param {*}
     * @return {*}
     */
    handleEnterClass() {
      const nowTimestamp = new Date().getTime();
      const classEnterTime = new Date(this.classListInfo.classTime).getTime();
      // console.log(nowTimestamp, classEnterTime);
      this.canEnterClass =
        classEnterTime - nowTimestamp <= 60 * 60 * 1000 &&
        classEnterTime - nowTimestamp > -25 * 60 * 1000
          ? true
          : false;
      // console.log(this.canEnterClass);
    },
    /**
     * @description: 提交取消课程
     * @param {*}
     * @return {*}
     */
    async submitCancelClass() {
      console.log(this.classListInfo);

      const sendData = {
        calendarEventId: this.classListInfo.calendarEventId,
        isKidsLesson: true,
      };
      const result = await cancelClass(sendData);
      if (result && result.code === 200) {
        this.$message({
          type: "success",
          message: "Cancel Class Successfully",
        });
        this.cancelClassDialogVisible = false;
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        this.$message({
          type: "error",
          message: "Cancel Class failed",
        });
      }
    },

    /**
     * @description: hover cancell dot
     * @param {*}
     * @return {*}
     */
    handleHoverDot() {
      // 打开取消框
      this.isCancellDotHover = true;
      this.isOpenCancellBtn = true;
    },
    /**
     * @description: 跳转到preview页面
     * @param {*}
     * @return {*}
     */
    jumpToPreview() {
      localStorage.setItem("teacherName", this.classListInfo.teacherName);

      this.$router.push({
        name: `PreviewLesson${this.classListInfo.lessonIndex}`,
      });
    },
    /**
     * @description: learningGoals 切换语言
     * @param {*} index
     * @return {*}
     */
    changeLanguage(index) {
      // console.log(JSON.parse(this.classListInfo.kidsBook.learningGoals));
      const learningGoalsInfo = JSON.parse(
        this.classListInfo.kidsBook.learningGoals
          ? this.classListInfo.kidsBook.learningGoals
          : {}
      );
      this.goalsLanguage = index;
      if (!index) {
        this.learningGoalsInfo = {
          speakTitle: "i can speak",
          useTitle: "i can use",
          speak: learningGoalsInfo.speakEn,
          use: learningGoalsInfo.useEn,
        };
      } else {
        this.learningGoalsInfo = {
          speakTitle: "我会认",
          useTitle: "我会用",
          speak: learningGoalsInfo.speak,
          use: learningGoalsInfo.use,
        };
      }
    },
    /**
     * @description: 切换feedback类型
     * @param {*} index
     * @return {*}
     */
    changeFeedback(index) {
      this.feedbackClassify = index;
    },
    /**
     * @description: 进入课堂
     * @param {*} index
     * @return {*}
     */
    enterInClass(index) {
      if (this.canEnterClass) {
        this.$store.commit("setCourseData", this.classListInfo);
        this.$router.push({
          path: `/course/calendarEventId=${this.calendarEventId}/lesson${index}`,
        });
      }
    },
    /**
     * @description: 打开学生feedback dialog
     * @param {*}
     * @return {*}
     */
    async openStudentComment() {
      const res = await getStudentComment({
        calendarEventId: this.classListInfo.calendarEventId,
      });
      // console.log(res);
      if (res && res.data && res.data.id === null) {
        this.isStudentFinishedFeedback = false;
      } else {
        this.isStudentFinishedFeedback = true;
        this.finishedStudentFeedbackInfo = {
          studentAvatar: this.classListInfo.userAvatar,
          studentName: this.classListInfo.userName,
          feedbackContent: res.data.evaluationContent,
          teacherScore: res.data.teacherScore,
          learningLevel: res.data.learningLevel,
        };
      }
      this.feedbackDialogVisible = true;
    },
    /**
     * @description: 星星评分
     * @param {*}
     * @return {*}
     */
    clickStar(currentIndex) {
      if (this.originIndex !== currentIndex) {
        this.starFlag = 0;
      }
      if (!this.starFlag) {
        this.originIndex = currentIndex;
        this.starArr.forEach((item, index) => {
          this.currentIndex = currentIndex;
          if (index > currentIndex) {
            item.starLevel = 0;
          } else if (index === currentIndex) {
            item.starLevel = 1;
          } else {
            item.starLevel = 2;
          }
        });
        this.starFlag++;
      } else {
        this.starArr[currentIndex].starLevel = 2;
        this.currentIndex = currentIndex + 1;
        this.starFlag = 0;
      }
      const half = this.starArr[currentIndex].starLevel === 1 ? ".5" : ".0";
      this.finalLevel = this.currentIndex + half;
      // console.log(this.finalLevel);
    },
    /**
     * @description: 提交学生feedback
     * @param {*}
     * @return {*}
     */
    async submitStedentFeedback() {
      const sendData = {
        calendarEventId: this.classListInfo.calendarEventId,
        teacherScore: parseInt(this.finalLevel),
        teacherId: this.classListInfo.teacherId,
        studentId: this.classListInfo.userId,
        evaluationContent: this.studentInput,
        learningLevel: this.difficultyOfLesson,
      };
      // console.log(this.classListInfo, sendData);
      const result = await submitStudentComment(sendData);
      // console.log(result);
      if (result && result.code === 200) {
        this.$message({
          message: "Thank you! Your feedback has been successfully saved",
          type: "success",
        });
        // this.feedbackDialogVisible = false;
        this.openStudentComment();
      } else {
        this.$message({
          message: "Sorry! Network Error",
          type: "error",
        });
        this.feedbackDialogVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<style lang="scss" scoped>
.page-content {
  background-color: transparent !important;
  padding: 0 !important;
}
.content {
  background-color: transparent !important;
}
</style>
